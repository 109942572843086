import React, {useState, useEffect} from "react";
import { Box, Typography, Grid, Card, CardContent, Button, List, ListItemIcon, ListItemText } from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import config from "../../../../config/config";
import img1 from "./banner.png";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import BusinessIcon from '@mui/icons-material/Business';

const HeroesMalvinas2025 = () => {
  const navigate = useNavigate();
  const [periods, setPeriods] = useState([]);
  const [totalInscriptos, setTotalInscriptos] = useState(0);
  const LIMITE_INSCRIPCIONES = 1144; // Límite máximo de inscriptos
  const raceName = "maraton_heroes_de_malvinas_2025"; // Nombre de la carrera

  // Obtener los periodos de inscripción desde el backend
  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchPeriods = async () => {
      try {
        const response = await axios.get(`${config.INSCRIPTION_URL}/api/inscription-periods`, {
          params: { raceName },
        });
        // Filtrar los periodos que son válidos para la fecha actual
        const currentDate = new Date();
        const filteredPeriods = response.data.periods.filter((period) => {
          const startDate = new Date(period.startDate); // Fecha de inicio del periodo
          const endDate = new Date(period.endDate); // Fecha de finalización del periodo
          return currentDate >= startDate && currentDate <= endDate; // Periodos activos
        });

        setPeriods(filteredPeriods);
      } catch (error) {
        console.error("Error al obtener los períodos:", error);
      }
    };

    // Obtener el total de inscriptos desde el endpoint de métricas
    const fetchTotalInscriptos = async () => {
      try {
        const response = await axios.get(`${config.INSCRIPTION_URL}/api/metrics/${raceName}`);
        setTotalInscriptos(response.data.totalRows || 0); // Usa totalRows para obtener el total de inscriptos
      } catch (error) {
        console.error("Error al obtener el total de inscriptos:", error);
      }
    };

    fetchPeriods();
    fetchTotalInscriptos();
  }, []);

  const handleInscribirse = (raceName, distance, periodoInscripcion, price, image) => {
    const numericPrice = parseFloat(price);
    const priceTax = numericPrice * 0.0149; // Calcula el 1.49% del precio original
    // Redirige al componente Forms con los datos seleccionados
    navigate(`/${raceName}/forms07`, {
      state: { distance, periodoInscripcion, price: numericPrice, priceTax: priceTax.toFixed(2), image },
    });
  };

  return (
    <Box
      sx={{
        backgroundColor: "#f5f5f5",
        padding: 4,
        borderRadius: 2,
        maxWidth: "90%",
        margin: "auto",
        marginTop: 4,
        marginBottom: 4,
        fontFamily: "Arial, sans-serif",
        boxShadow: "0px 1px 20px rgba(100, 0, 0, 100)",
      }}
    >
      {/* Banner Image */}
      <Box
        component="img"
        src={img1}
        alt="Banner"
        sx={{
          width: "100%",
          borderRadius: 2,
          marginBottom: 3,
          maxHeight: "300px",
        }}
      />

      <Typography
        variant="h6"
        gutterBottom
        sx={{
          fontFamily: "Monserat, sans-serif",
          fontWeight: 600,
          fontSize: "1.5rem",
          textTransform: "uppercase",
          textDecoration: "underline",
          textDecorationColor: "red",
          textDecorationThickness: "2px",
        }}
      >
        MARATÓN HEROES DE MALVINAS 2025
      </Typography>

      <List sx={{ marginTop: '30px', display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
          <ListItemIcon sx={{ minWidth: 'auto', marginRight: '8px' }}>
            <CalendarMonthIcon style={{ color: 'red', fontSize: '30px' }} />
        </ListItemIcon>
        <ListItemText primary="6 de Abril del 2025" primaryTypographyProps={{ style: { fontSize: '1.2rem', textAlign: 'left' } }} />
      </List>

      <List sx={{ marginTop: '10px', display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
          <ListItemIcon sx={{ minWidth: 'auto', marginRight: '8px' }}>
            <LocationOnIcon style={{ color: 'red', fontSize: '30px' }} />
        </ListItemIcon>
        <ListItemText primary="Merlo - San Luis" primaryTypographyProps={{ style: { fontSize: '1.2rem', textAlign: 'left' } }} />
      </List>

      <List sx={{ marginTop: '10px', display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
          <ListItemIcon sx={{ minWidth: 'auto', marginRight: '8px' }}>
            <BusinessIcon style={{ color: 'red', fontSize: '30px' }} />
        </ListItemIcon>
        <ListItemText primary="Organiza: Municipalidad de Merlo" primaryTypographyProps={{ style: { fontSize: '1.2rem', textAlign: 'left' } }} />
      </List>
   
      <Typography
        variant="h6"
        gutterBottom
        sx={{
          marginTop: 1,
          marginBottom: 5,
          fontFamily: "Monserat, sans-serif",
          fontWeight: 600,
          fontSize: "1.5rem",
          textTransform: "uppercase",
          textDecoration: "underline",
          textDecorationColor: "red",
          textDecorationThickness: "2px",
        }}
      >
        Inscripciones
      </Typography>

      <Typography variant="h6" sx={{ color: totalInscriptos >= LIMITE_INSCRIPCIONES ? "red" : "green", marginBottom: 2 }}>
        {totalInscriptos >= LIMITE_INSCRIPCIONES ? "INSCRIPCIONES AGOTADAS" : ""}
      </Typography>

      {/*Aqui arrancan las card de las distancias*/}
              <Grid item xs={12} sm={12}>
              <Grid item xs={12} sm={12}>
              <Grid container spacing={4} justifyContent="center">
                {periods.length > 0 ? (
                  <>
                    {/* Tarjetas para otros periodos */}
                    {periods.some((period) => period.periodName !== "Correcaminata") && (
                      <>
                        {periods
                          .filter((period) => period.periodName !== "Correcaminata")
                          .map((period) => (
                            <Grid item xs={12} sm={4} md={3} key={period.id}>
                              <Card
                                variant="outlined"
                                sx={{
                                  maxWidth: 300,
                                  transition: "0.2s",
                                  boxShadow: "0px 1px 20px rgba(100, 0, 0, 100)",
                                  borderRadius: 3,
                                  "&:hover": {
                                    transform: "scale(1.05)",
                                  },
                                }}
                              >
                                <CardContent>
                                  <Typography variant="h2" gutterBottom>
                                    {period.distance}
                                  </Typography>
                                  <Typography variant="h7" gutterBottom>
                                    {period.periodName}
                                  </Typography>
                                  <Typography variant="h5">${period.price}</Typography>
                                </CardContent>
                                <Box sx={{ textAlign: "center", paddingBottom: 2 }}>
                                  <Button
                                    variant="contained"
                                    color="error"
                                    onClick={() =>
                                      handleInscribirse(
                                        "Maraton Heroes De Malvinas 2025",
                                        period.distance,
                                        period.periodName,
                                        period.price,
                                        img1
                                      )
                                    }
                                    disabled={totalInscriptos >= LIMITE_INSCRIPCIONES}
                                  >
                                    Inscribirse
                                  </Button>
                                  
                                </Box>
                              </Card>
                            </Grid>
                          ))}
                      </>
                    )}
            
                    {/* Tarjetas para "Adaptado: Motriz, Visual, Intelectual o Down" */}
                    {periods.some((period) => period.periodName === "Correcaminata") && (
                      <>
                        <Grid item xs={12}>
                          <Typography
                              variant="h5"
                              gutterBottom
                              sx={{marginTop: 4, fontFamily: "Monserat, sans-serif", fontWeight: 600, fontSize: "1.2rem"}}>
                              Correcaminata integradora e inclusiva GRATUITA.<br></br><br></br>NO INCLUYE KIT
                          </Typography>
                        </Grid>
                        {periods
                          .filter((period) => period.periodName === "Correcaminata")
                          .map((period) => (
                            <Grid item xs={12} sm={4} md={3} key={period.id}>
                              <Card
                                variant="outlined"
                                sx={{
                                  maxWidth: 300,
                                  transition: "0.2s",
                                  boxShadow: "0px 1px 20px rgba(100, 0, 0, 100)",
                                  borderRadius: 3,
                                  "&:hover": {
                                    transform: "scale(1.05)",
                                  },
                                }}
                              >
                                <CardContent>
                                  <Typography variant="h2" gutterBottom>
                                    {period.distance}
                                  </Typography>
                                  <Typography variant="h7" gutterBottom>
                                    {period.periodName}
                                  </Typography>
                                  <Typography variant="h5">${period.price}</Typography>
                                </CardContent>
                                <Box sx={{ textAlign: "center", paddingBottom: 2 }}>
                                  <Button
                                    variant="contained"
                                    color="error"
                                    onClick={() =>
                                      handleInscribirse(
                                        "Maraton Heroes De Malvinas 2025",
                                        period.distance,
                                        period.periodName,
                                        period.price,
                                        img1
                                      )
                                    }
                                    disabled={totalInscriptos >= LIMITE_INSCRIPCIONES}
                                  >
                                    Inscribirse
                                  </Button>
                                  
                                </Box>
                              </Card>
                            </Grid>
                          ))}
                      </>
                    )}
                  </>
                ) : (
                  <Typography variant="h6" gutterBottom sx={{ marginTop: 8, fontFamily: "Monserat, sans-serif", fontWeight: 600, fontSize: "1.5rem", marginLeft: 5 }}>
                  {totalInscriptos >= LIMITE_INSCRIPCIONES ? "INSCRIPCIONES AGOTADAS" : "Próximamente disponibles!"}
                </Typography>
                )}
              </Grid>
            </Grid>
            </Grid>
          </Box>
  );
};

export default HeroesMalvinas2025;
