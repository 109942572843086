import React, { useState, useEffect } from 'react';
import { TextField, Box, Typography, Grid, Button, CardContent, Card, Checkbox, FormControlLabel } from '@mui/material';
import { useParams, useLocation } from 'react-router-dom';
import axios from 'axios';
import { initMercadoPago, Wallet } from '@mercadopago/sdk-react';
import { v4 as uuidv4 } from 'uuid';
import config from '../../../../config/config.js';
import mercadoPagoLogo from '../../../../images/ImgInscripciones/MercadoPagoLogo.png';
import paypalLogo from '../../../../images/ImgInscripciones/PayPalLogo.png';
import efectivoLogo from '../../../../images/ImgInscripciones/EfectivoLogo.png';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";


const Forms01 = () => {
  const { raceName } = useParams();
  const { state } = useLocation();
  const { distance, price, periodoInscripcion, priceTax, image } = state || {};
  const formattedRaceName = raceName.toLowerCase().replace(/\s+/g, '_');
  const [discountCode, setDiscountCode] = useState('');
  const [discountedPrice, setDiscountedPrice] = useState(null);
  const [preferenceId] = useState(null);
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [selectedPayment, setSelectedPayment] = useState("Mercado Pago");
  const [showPayPalButton, setShowPayPalButton] = useState(false);
  const [paypalOrderId, setPaypalOrderId] = useState(null);
  const paymentMethods = [
    { name: 'Mercado Pago', logo: mercadoPagoLogo },
    { name: 'PayPal', logo: paypalLogo },
    { name: 'Pago en efectivo', logo: efectivoLogo },
  ];
  
  // Inicializa Mercado Pago solo cuando el componente se monta
  useEffect(() => {
    initMercadoPago('APP_USR-72879720-3f27-4cb1-b4fd-38f4ba5edaea', { locale: 'es-AR' });
  }, []); // El array vacío asegura que se ejecuta solo una vez

  const createPreference = async (formId) => {
    try {
      const priceToPay = calculatePrice();
      const payerEmail = email; // Usa el email capturado del estado
      const applicationFee = price * 0.03;
      const sellerAmount = priceToPay - applicationFee;
    
      /*const response = await axios.post('http://localhost:5000/create_preference', { */
      const response = await axios.post(`${config.INSCRIPTION_URL}/create_preference`, {
        title: `Inscripción - ${distance} - ${periodoInscripcion} - ${raceName}`,
        quantity: 1,
        price: priceToPay,
        formId,
        payerEmail,      // Añadir el email del pagador
        applicationFee,  // Añadir la comisión del marketplace
        sellerAmount,
        raceName: formattedRaceName,
      });
      /*console.log('Respuesta de Mercado Pago:', response.data);*/
      const { initPoint } = response.data;
    return initPoint;
    } catch (error) {
      console.error('Error al crear la preferencia:', error);
      alert("Hubo un error al procesar el pago. Intenta nuevamente.");
      return null;
    }
  };

  const handlePaymentChange = (event) => {
    setSelectedPayment(event.target.value);
  };

  const handleDiscountCodeChange = (event) => {
    setDiscountCode(event.target.value);
  };

const applyDiscount = async () => {
  if (!discountCode) {
    alert("Por favor, ingresa un código de descuento.");
    return;
  }

  try {
    // Llamada al backend para validar el código
    const response = await axios.get(`${config.INSCRIPTION_URL}/api/discounts`, {
      params: { raceName: formattedRaceName, code: discountCode },
    });

    const { discountType, discountValue, expirationDate, usageCount, cantidad } = response.data;

    // Validar que el código no haya expirado
    const today = new Date();
    if (new Date(expirationDate) < today) {
      alert("El código de descuento ha expirado.");
      return;
    }

    // Validar si el código alcanzó su límite de usos
    if (usageCount >= cantidad) {
      alert("El código de descuento ya no está disponible.");
      return;
    }

    // Calcular el precio con descuento
    let newPrice = price; // Precio inicial

    if (discountType === "percentage") {
      const discountFactor = 1 - discountValue / 100; // Convierte el porcentaje en un factor de descuento
      newPrice = price * discountFactor;
    } else if (discountType === "amount") {
      newPrice = price - discountValue; // Resta el monto fijo
    }
/** */
     // Asegurarse de que el precio no sea negativo (o inválido)
     newPrice = Math.max(newPrice, 0); // Si es menor a 0, lo fuerza a 0

     // Verifica y actualiza el estado
     if (newPrice === 0) {
       console.log("El precio con descuento es 0.");
     }

    setDiscountedPrice(newPrice);
    try {
      await axios.post(`${config.INSCRIPTION_URL}/api/discounts/use`, {
        raceName: formattedRaceName,
        code: discountCode,
      });
      setMessage('Codigo de descuento aplicado');
      console.log("Uso del código registrado con éxito.");
    } catch (error) {
      console.error("Error al registrar el uso del código de descuento:", error);
    }
  } catch (error) {
    console.error("Error al validar el código de descuento:", error);
    if (error.response && error.response.status === 404) {
      alert("El código de descuento no es válido.");
    } else {
      alert("El código de descuento ya no está disponible.");
    }
  }
};

  const handleFormSubmit = async () => {
    const formId = uuidv4();
    const finalPrice = calculatePrice(); // Obtén el precio calculado
    const formData = {
      formId,
      FirstName: document.getElementById('first_name').value,
      LastName: document.getElementById('last_name').value,
      DNI: document.getElementById('document_number').value,
      email: document.getElementById('email').value,
      distance,
      price,
      discount_code: discountCode,
      discounted_price: discountedPrice || price,
      priceTax,
      paymentOption: '100%',
      payment_price: finalPrice,
      payment_status: finalPrice === 0 ? 'free' : null, // Lógica actualizada
      pending_price: calculatePendingPrice(),
      periodoInscripcion,
      raceName: formattedRaceName,
    };
    setEmail(formData.email); // Establece el correo electrónico para la preferencia
    try {
      /**await axios.post('http://localhost:5000/api/submitform', formData); */
      await axios.post(`${config.INSCRIPTION_URL}/submitform`, formData);
      console.log('Formulario enviado correctamente');
      return formId;
    } catch (error) {
      console.error('Error al enviar el formulario:', error);
      return null;
    }
  };

const handleButtonClick = async () => {
  console.log("Método de pago al hacer clic:", selectedPayment);
  // Primero envía los datos del formulario y obtiene el formId
  const formId = await handleFormSubmit();
  const raceName = formattedRaceName;

  if (!formId) {
    console.error("Error al enviar el formulario");
    return;
  }

  const finalPrice = calculatePrice(); // Calculamos el precio final

  // Si el precio final es 0, se finaliza la inscripción sin pasar por Mercado Pago
  if (finalPrice === 0) {
    alert("Inscripción realizada con éxito");
    window.location.href = "https://esfuerzodeportivosr.com.ar/"; // Redirige al home
    return;
  }

  // Si el usuario seleccionó "Pago en efectivo", también finaliza sin Mercado Pago
  if (selectedPayment === "Pago en efectivo") {
    alert("Inscripción realizada con éxito - Pago en efectivo seleccionado.");
    window.location.href = "https://esfuerzodeportivosr.com.ar/"; // Redirige al home
    return;
  }

  // Si el usuario seleccionó PayPal, crear la orden y redirigir
  if (selectedPayment === "PayPal") {
    try {
      const { data } = await axios.post(`${config.INSCRIPTION_URL}/api/create-order`, {
        amount: finalPrice.toString(), 
        currency: "USD",
        formId: formId,
        raceName: raceName,
      });

      if (data.id) {
        setPaypalOrderId(data.id);
        setShowPayPalButton(true);  
        console.log("✅ Orden de PayPal creada:", data.id);
        return;
      } else {
        throw new Error("No se recibió un ID de orden de PayPal");
      }
    } catch (error) {
      console.error("❌ Error al procesar el pago con PayPal:", error);
      alert("Error al procesar el pago con PayPal.");
      return;
    }
  }

  // Si ninguna de las condiciones anteriores se cumple, se crea la preferencia de Mercado Pago
  const initPoint = await createPreference(formId);
  if (initPoint) {
    window.location.href = initPoint; // Redirige al usuario a pagar en Mercado Pago
  }
};

const calculatePrice = () => {
    const basePrice = parseFloat(discountedPrice || price);
    return (basePrice + parseFloat(priceTax)).toFixed(2); // Siempre retorna el precio total
  };

  const calculatePendingPrice = () => {
    return null; // Siempre null
  };
  
  return (
    <Box
      sx={{
        backgroundColor: '#f5f5f5',
        padding: { xs: 2, md: 4},
        maxWidth: '100%',
        margin: 'auto',
        fontFamily: 'Arial, sans-serif',
      }}
    >
      
      <Box
      sx={{
        backgroundColor: '#f5f5f5',
        width: '100%',
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' }, // Columna en pantallas pequeñas, fila en pantallas grandes
        justifyContent: 'center',
        fontFamily: 'Arial, sans-serif',
      }}
    >
      <Box
      sx={{
        backgroundColor: '#f5f5f5',
        padding: { xs: 2, md: 4},
        borderRadius: 2,
        maxWidth: { xs: '100%', md: '80%' },
        fontFamily: 'Arial, sans-serif',
        boxShadow: "0px 1px 10px black",
        marginBottom: { xs: 2, md: 0 },
      }}
    >
      <Box
        component="img"
        src={image}
        alt="Banner"
        sx={{
          width: '100%',
          borderRadius: 2,
          marginBottom: 3,
          maxHeight: '300px',
          boxShadow: "0px 1px 10px black",
        }}
      />
      <Typography variant="h6" gutterBottom sx={{ fontFamily: 'Monserat, sans-serif', fontWeight: 600, fontSize: '1.5rem', textTransform: 'uppercase', textDecoration: 'underline', textDecorationColor: 'red', textDecorationThickness: '2px'}}>
        Datos del corredor
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            id="first_name"
            label="Nombre"
            variant="outlined"
            sx={{
              '& .MuiInputLabel-root': { color: '#333' },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#ccc',
                },
                '&:hover fieldset': {
                  borderColor: '#666',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#1976d2',
                },
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            id="last_name"
            label="Apellido"
            variant="outlined"
            sx={{
              '& .MuiInputLabel-root': { color: '#333' },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#ccc',
                },
                '&:hover fieldset': {
                  borderColor: '#666',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#1976d2',
                },
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            id="document_number"
            label="Número de Documento"
            variant="outlined"
            sx={{
              '& .MuiInputLabel-root': { color: '#333' },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#ccc',
                },
                '&:hover fieldset': {
                  borderColor: '#666',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#1976d2',
                },
              },
            }}
          />
        </Grid>
        
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            id="email"
            label="Correo Electrónico"
            variant="outlined"
            type="email"
            sx={{
              '& .MuiInputLabel-root': { color: '#333' },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#ccc',
                },
                '&:hover fieldset': {
                  borderColor: '#666',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#1976d2',
                },
              },
            }}
          />
        </Grid>
      </Grid>

              <Typography variant="h6" gutterBottom sx={{marginTop: 4, fontFamily: 'Monserat, sans-serif', fontWeight: 600,     fontSize: '1.3rem', textTransform: 'uppercase', textDecoration: 'underline', textDecorationColor: 'red', textDecorationThickness: '2px'}}>
               Pago de la inscripción en {raceName}
              </Typography>

          <Grid container spacing={2} alignItems="stretch">
          <Grid item xs={12} sm={6} sx={{ display: "flex" }}>
          <Card variant="outlined"
                sx={{transition: "0.2s", boxShadow: "0px 1px 10px black", borderRadius: 1, width: "100%", display: "flex", flexDirection: "column" }}>
            <CardContent sx={{ flexGrow: 1 }}>
              <Typography variant="h6" gutterBottom>
                {distance} - {periodoInscripcion}
              </Typography>
            <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', marginTop: 2 }}>
                    Precio: ${(discountedPrice || price).toFixed(2)}
            </Typography>
            <Typography variant="h7" gutterBottom sx={{ fontWeight: 'bold' }}>
                    Precio del servicio: ${priceTax}
            </Typography>
             <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', marginTop: 2 }}>
                    Precio Total: ${calculatePrice()}
            </Typography> 

            <Grid container spacing={2} alignItems="center">
            <Grid item xs={8}>
              <TextField
                fullWidth
                id="discountCode"
                label="Código de descuento"
                variant="outlined"
                value={discountCode}
                onChange={handleDiscountCodeChange}
                sx={{
                  height: '35px', // Altura igual a la del botón
                  '& .MuiInputLabel-root': {
                    color: '#333',
                    marginLeft: '14px',
                    top: '50%', // Centra el label verticalmente
                    transform: 'translateY(-50%)', // Ajuste preciso
                    transition: 'opacity 0.2s ease-in-out',
                  },
                  
                  '& .MuiOutlinedInput-root': {
                    height: '100%', // Hace que el campo tenga la misma altura que el botón
                    display: 'flex',
                    alignItems: 'center',
                  },
                  
                 '& .MuiInputLabel-root.MuiFormLabel-filled': {
                    opacity: 0,
                  },
                }}
              />
            </Grid>
            <Grid item xs={4}>
            <Button
              fullWidth
              variant="contained"
              onClick={applyDiscount}
              sx={{
                height: '35px',
                backgroundColor: 'red',
                color: 'white',
                '&:hover': {
                  backgroundColor: 'darkred',
                },
              }}
            >
              Aplicar
            </Button>
            </Grid>
          </Grid>
          {message}
          </CardContent>
          </Card>
          </Grid>

          <Grid item xs={12} sm={6} sx={{ display: "flex" }}>
          <Card variant="outlined" sx={{transition: "0.2s", boxShadow: "0px 1px 10px black", borderRadius: 1, width: "100%", display: "flex", flexDirection: "column" }}>
            <CardContent sx={{ flexGrow: 1 }}>
              <Typography variant="h6" gutterBottom>
                Medio de pago
              </Typography>
              <Grid container direction="column">
              {paymentMethods.map((method) => (
                <Grid item key={method.name}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedPayment === method.name}
                        onChange={handlePaymentChange}
                        value={method.name}
                        name="paymentMethod"
                        defaultChecked={method.name === "Mercado Pago"}
                        sx={{ color: "red", "&.Mui-checked": { color: "red" } }}
                      />
                    }
                    label={
                      <Box display="flex" alignItems="center">
                        <img src={method.logo} alt={method.name} width="100" style={{ marginRight: 10 }} />
                      </Box>
                    }
                  />
                </Grid>
              ))}
              <Typography>Pago efectivo solo en Rustik San Juan</Typography>

            </Grid>
            </CardContent>
          </Card>
          </Grid>
          </Grid>

          <PayPalScriptProvider options={{ "client-id": "AUhevs9E9kp101hbmQ0LI5NU-PVso4zf85CdsA33Wx78Fe38PLfn86JfyZNi8EMX1dO-I0BzCvnNFjoq", currency: "USD" }}>
          <Button
            fullWidth
            variant="contained"
            onClick={handleButtonClick}
            sx={{
              marginTop: 3,
              backgroundColor: 'red',
              color: 'white',
              '&:hover': {
                backgroundColor: 'darkred',
              },
            }}
          >
            Finalizar
          </Button> 
          {preferenceId && (
            <Wallet
              initialization={{ preferenceId: preferenceId }}
              customization={{ texts: { valueProp: 'smart_option' } }}
            />
          )}

          {showPayPalButton && paypalOrderId && (
      <PayPalButtons
      createOrder={(data, actions) => {
        return actions.order.create({
          purchase_units: [
            {
              amount: {
                currency_code: "USD",
                value: calculatePrice().toString(), 
              },
            },
          ],
        });
      }}
      onApprove={async (data, actions) => {
        try {
          const details = await actions.order.capture();
          console.log("✅ Pago exitoso:", details);
      
          // Consultar al backend en lugar de confiar en lo que devuelve PayPal
          const response = await axios.post(`${config.INSCRIPTION_URL}/api/paypal/webhook`, {
            orderId: details.id,
          });
          const { formId, raceName } = response.data;
      
          if (!formId || !raceName) {
            console.error("❌ Error: No se pudieron extraer formId y raceName.");
            alert("Hubo un problema con la información del pago.");
            return;
          }
      
          alert("Pago completado con éxito.");
          window.location.href = "https://esfuerzodeportivosr.com.ar/";
        } catch (error) {
          console.error("❌ Error al obtener datos del pago:", error);
          alert("El pago se realizó, pero hubo un problema al verificar los datos.");
        }
      }}
      
    />
  )}
</PayPalScriptProvider>
        </Box>
      </Box>
    </Box>
  );
};

export default Forms01;