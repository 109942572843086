import React, {useState, useEffect} from "react";
import { Box, Typography, Grid, Card, CardContent, Button, List, ListItemIcon, ListItemText } from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import config from "../../../../config/config";
import img1 from "../MerloLate2025/banner.png";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import BusinessIcon from '@mui/icons-material/Business';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import deslinde from '../MerloLate2025/Merlo Late deslinde.pdf'

const MerloLate2025 = () => {
  const navigate = useNavigate();
  const [periods, setPeriods] = useState([]);
  const isSoldOut = false;
 
  // Obtener los periodos de inscripción desde el backend
  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchPeriods = async () => {
      try {
        const response = await axios.get(`${config.INSCRIPTION_URL}/api/inscription-periods`, {
          params: { raceName: "merlo_late_2025" }, // Filtra por nombre de la carrera
        });
        // Filtrar los periodos que son válidos para la fecha actual
        const currentDate = new Date();
        const filteredPeriods = response.data.periods.filter((period) => {
          const startDate = new Date(period.startDate); // Fecha de inicio del periodo
          const endDate = new Date(period.endDate); // Fecha de finalización del periodo
          return currentDate >= startDate && currentDate <= endDate; // Periodos activos
        });

        setPeriods(filteredPeriods);
      } catch (error) {
        console.error("Error al obtener los períodos:", error);
      }
    };

    fetchPeriods();
  }, []);

  const handleInscribirse = (raceName, distance, periodoInscripcion, price, image) => {
    const numericPrice = parseFloat(price);
    const priceTax = numericPrice * 0.0739; // Calcula el 7.39% del precio original
    // Redirige al componente Forms con los datos seleccionados
    navigate(`/${raceName}/forms06`, {
      state: { distance, periodoInscripcion, price: numericPrice, priceTax: priceTax.toFixed(2), image },
    });
  };

  return (
    <Box
      sx={{
        backgroundColor: "#f5f5f5",
        padding: 4,
        borderRadius: 2,
        maxWidth: "90%",
        margin: "auto",
        marginTop: 4,
        marginBottom: 4,
        fontFamily: "Arial, sans-serif",
        boxShadow: "0px 1px 20px rgba(100, 0, 0, 100)",
      }}
    >
      {/* Banner Image */}
      <Box
        component="img"
        src={img1}
        alt="Banner"
        sx={{
          width: "100%",
          borderRadius: 2,
          marginBottom: 3,
          maxHeight: "300px",
          boxShadow: "0px 1px 10px black",
        }}
      />
      
      <List sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
          <ListItemIcon sx={{ minWidth: 'auto', marginRight: '8px' }}>
            <CalendarMonthIcon style={{ color: 'red', fontSize: '30px' }} />
        </ListItemIcon>
        <ListItemText primary="27 de Septiembre del 2025" primaryTypographyProps={{ style: { fontSize: '1.2rem', textAlign: 'left' } }} />
      </List>

      <List sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
          <ListItemIcon sx={{ minWidth: 'auto', marginRight: '8px' }}>
            <LocationOnIcon style={{ color: 'red', fontSize: '30px' }} />
        </ListItemIcon>
        <ListItemText primary="Villa de Merlo - San Luis" primaryTypographyProps={{ style: { fontSize: '1.2rem', textAlign: 'left' } }} />
      </List>

      <List sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
          <ListItemIcon sx={{ minWidth: 'auto', marginRight: '8px' }}>
            <BusinessIcon style={{ color: 'red', fontSize: '30px' }} />
        </ListItemIcon>
        <ListItemText primary="MERLO TRAIL RUN" primaryTypographyProps={{ style: { fontSize: '1.2rem', textAlign: 'left' } }} />
      </List>

      <List sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
          <ListItemIcon sx={{ minWidth: 'auto', marginRight: '8px' }}>
            <ContactPhoneIcon style={{ color: 'red', fontSize: '30px' }} />
        </ListItemIcon>
        <ListItemText primary="Consultas: +54 9 266 485-2841" primaryTypographyProps={{ style: { fontSize: '1.2rem', textAlign: 'left' } }} />
      </List>
      
      <Typography
              variant="h6"
              gutterBottom
              sx={{
                marginTop: 2,
                fontFamily: "Monserat, sans-serif",
                fontWeight: 600,
                fontSize: "1.5rem",
                textTransform: "uppercase",
                textDecoration: "underline",
                textDecorationColor: "red",
                textDecorationThickness: "2px",
              }}
            >
              MERLO LATE 2025
            </Typography>

      <Typography variant="body1" sx={{textAlign: "left"}}>
        Merlo late es un proyecto concientizado en la prevención de cardiopatías de la mujer. Conmemorando el día Internacional de la Mujer.
        <br></br><br></br>
        Merlo late se realizará el día 15 de Marzo 2025. Siendo su epicentro la residencia del hospital Madre Catalina. Dónde se desarrolla una corre caminata de 6k y una carrera pedestre de 12k.
        <br></br><br></br>
        El cronograma de actividades iniciar a las 17hs con acreditación a las distancias y entrega de kit.
        <br></br><br></br>
        Y la largada de las 2 distancias se realizará a las 19.30hs.
        <br></br><br></br>
        La distancias clasificatoria (competitiva) es la de 12k donde se premiará los 5 primeros en la general masculino y femenino. Y del 1ro al 3ro masculino y femenino cada 10 años.
        <br></br><br></br>
        Categorías masculino y femenino<br></br>• 12 a 19 años<br></br>• 20 a 29 años<br></br>• 30 a 39 años<br></br>• 40 a 49 años<br></br>• 50 a 59 años<br></br>• + De 60 años.
        <br></br><br></br>
        Dicho circuito se desarrolla por calles de asfalto senderos calle de tierra y arroyo seco, recorriendo sectores de diferentes paisajes de Merlo, dicho recorrido están señalizador y con marcación para su correcto tránsito.
        <br></br><br></br>
        El valor de la inscripción es único iniciando la apertura el día 20/02 y finalizando el día 12/03.
        <br></br><br></br>
        Requisitos para la acreditación deberá presentar certificado medico (apto físico) con vigencia de no mas de un año.
        <br></br>
        Cupón de pago de inscripción.
        <br></br>
        Firmar el deslinde de responsabilidad.
        <br></br>
        Donar unos útiles escolares que serán destinados a escuelas rurales.</Typography>
      <Typography
              variant="h6"
              gutterBottom
              sx={{
                marginTop: 8,
                fontFamily: "Monserat, sans-serif",
                fontWeight: 600,
                fontSize: "1.5rem",
                textTransform: "uppercase",
                textDecoration: "underline",
                textDecorationColor: "red",
                textDecorationThickness: "2px",
              }}
            >
              DOCUMENTACIÓN IMPORTANTE
            </Typography>
            
            <Button
              variant="contained"
              color="primary"
              href={deslinde} // Usa `href` en lugar de `src` para abrir el PDF
              target="_blank" // Abre el PDF en una nueva pestaña
              rel="noopener noreferrer" // Mejora de seguridad al abrir un enlace externo
              sx={{
                marginTop: 2,
                marginRight: 5,
                backgroundColor: "red",
                ":hover": { backgroundColor: "darkred", color: "white" },
              }}
            >
              Deslinde de responsabilidades
            </Button>

       <Typography
               variant="h6"
               gutterBottom
               sx={{
                 marginTop: 8,
                 fontFamily: "Monserat, sans-serif",
                 fontWeight: 600,
                 fontSize: "1.5rem",
                 textTransform: "uppercase",
                 textDecoration: "underline",
                 textDecorationColor: "red",
                 textDecorationThickness: "2px",
               }}
             >
               Inscripciones
             </Typography>

      {/*Aqui arrancan las card de las distancias*/}
            <Grid item xs={12} sm={12}>
                <Grid container spacing={4} justifyContent="center">
                  {periods.length > 0 ? (
                    periods.map((period) => (
                      <Grid item xs={12} sm={4} md={3} key={period.id}>
                        <Card
                          variant="outlined"
                          sx={{
                            maxWidth: 300,
                            transition: "0.2s",
                            boxShadow: "0px 1px 20px rgba(100, 0, 0, 100)",
                            borderRadius: 3,
                            "&:hover": {
                              transform: "scale(1.05)",
                            },
                          }}
                        >
                          <CardContent>
                            <Typography variant="h2" gutterBottom>
                              {period.distance}
                            </Typography>
                            <Typography variant="h7" gutterBottom>
                              {period.periodName}
                            </Typography>
                            <Typography variant="h5">${period.price}</Typography>
                          </CardContent>
                          <Box sx={{ textAlign: "center", paddingBottom: 2 }}>
                            <Button
                              variant="contained"
                              color="error"
                              onClick={() =>
                                handleInscribirse(
                                  "Merlo Late 2025",
                                  period.distance,
                                  period.periodName,
                                  period.price,
                                  img1
                                )
                              }
                              disabled={isSoldOut} // Desactiva el botón si los cupos están agotados
                            >
                              Inscribirse
                            </Button>
                            {isSoldOut && (
                              <Box
                                sx={{
                                  textAlign: "center",
                                  backgroundColor: "rgba(255, 0, 0, 0.7)",
                                  color: "white",
                                  padding: 1,
                                }}
                              >
                                <Typography variant="h6">Cupos agotados</Typography>
                                <Typography
                                  variant="h6"
                                  sx={{ fontSize: "0.8rem" }}
                                >
                                  Proximamente nuevo periodo
                                </Typography>
                              </Box>
                            )}
                          </Box>
                        </Card>
                      </Grid>
                    ))
                  ) : (
                    <Typography variant="h6" gutterBottom sx={{marginTop: 8, fontFamily: "Monserat, sans-serif", fontWeight: 600, fontSize: "1.5rem"}}>Próximamente disponibles!</Typography>
                  )}
                </Grid>
              </Grid>
          </Box>
        );
      };

export default MerloLate2025;
