import React, {useState, useEffect} from "react";
import { Box, Typography, Grid, Card, CardContent, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import config from "../../../../config/config";
import img1 from "../8-MaratonSanRafael2025/banner.jpg";
import deslinde from '../8-MaratonSanRafael2025/Deslinde.pdf'

const MaratonSanRafael2025 = () => {
  const navigate = useNavigate();
  const [periods, setPeriods] = useState([]);
  const isSoldOut = false;

  // Obtener los periodos de inscripción desde el backend
  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchPeriods = async () => {
      try {
        const response = await axios.get(`${config.INSCRIPTION_URL}/api/inscription-periods`, {
          params: { raceName: "maraton_de_san_rafael_2025" }, // Filtra por nombre de la carrera
        });
        // Filtrar los periodos que son válidos para la fecha actual
        const currentDate = new Date();
        const filteredPeriods = response.data.periods.filter((period) => {
          const startDate = new Date(period.startDate); // Fecha de inicio del periodo
          const endDate = new Date(period.endDate); // Fecha de finalización del periodo
          return currentDate >= startDate && currentDate <= endDate; // Periodos activos
        });

        setPeriods(filteredPeriods);
      } catch (error) {
        console.error("Error al obtener los períodos:", error);
      }
    };

    fetchPeriods();
  }, []);

  const handleInscribirse = (raceName, distance, periodoInscripcion, price, image) => {
    const numericPrice = parseFloat(price);
    const priceTax = numericPrice * 0.0349; // Calcula el 3.49% del precio original
    // Redirige al componente Forms con los datos seleccionados
    navigate(`/${raceName}/forms08`, {
      state: { distance, periodoInscripcion, price: numericPrice, priceTax: priceTax.toFixed(2), image },
    });
  };

  return (
    <Box
      sx={{
        backgroundColor: "#f5f5f5",
        padding: 4,
        borderRadius: 2,
        maxWidth: "90%",
        margin: "auto",
        marginTop: 4,
        marginBottom: 4,
        fontFamily: "Arial, sans-serif",
        boxShadow: "0px 1px 20px rgba(100, 0, 0, 100)",
      }}
    >
      {/* Banner Image */}
      <Box
        component="img"
        src={img1}
        alt="Banner"
        sx={{
          width: "100%",
          borderRadius: 2,
          marginBottom: 3,
          maxHeight: "300px",
        }}
      />

      <Typography
        variant="h6"
        gutterBottom
        sx={{
          fontFamily: "Monserat, sans-serif",
          fontWeight: 600,
          fontSize: "1.5rem",
          textTransform: "uppercase",
          textDecoration: "underline",
          textDecorationColor: "red",
          textDecorationThickness: "2px",
        }}
      >
        MARATÓN DE SAN RAFAEL 2025
      </Typography>
      <Typography variant="body1" sx={{textAlign: "left"}}>
      <Typography sx={{textDecoration: 'underline', fontWeight: 'bold', marginTop: 2}}>El evento:</Typography>
      Organizado por la UTN y la Municipalidad de San Rafael, y con la colaboración de Esfuerzo Deportivo, esta Maratón tiene su recorrido por las calles de la Ciudad y distritos de San Rafael, las categorías serán de 4 km en forma participativa y de 10 km, 21 km y 42 km de manera competitiva.
      <Typography sx={{textDecoration: 'underline', fontWeight: 'bold', marginTop: 2}}>Fecha, hora y lugar de largada:</Typography>
        Domingo 31 de agosto<br></br><br></br><strong>Largada 42 k: "CTDR" LOS REYUNOS</strong><br></br>Hora: 9 am<br></br><br></br><strong>Largada 21 k: ROTONDA DEL CRISTO</strong><br></br>Hora: 9:45 am<br></br><br></br>
        <strong>Largada 10 k: ROTONDA DEL MAPA (Por calle Cinca)</strong><br></br>Hora: 10 am<br></br><br></br><strong>Largada 4 k:  ROTONDA DEL MAPA (Por calle Cinca)</strong><br></br>Hora: 10 am
        <Typography sx={{textDecoration: 'underline', fontWeight: 'bold', marginTop: 2}}>Llegadas</Typography>
        Llegada de todas las categorías: Centro Integrador Universitario (General Paz y Urquiza)
      </Typography>

      <Typography
              variant="h6"
              gutterBottom
              sx={{
                marginTop: 8,
                fontFamily: "Monserat, sans-serif",
                fontWeight: 600,
                fontSize: "1.5rem",
                textTransform: "uppercase",
                textDecoration: "underline",
                textDecorationColor: "red",
                textDecorationThickness: "2px",
              }}
            >
              DOCUMENTACIÓN IMPORTANTE
            </Typography>
            
            {/* Enlace de vista previa del PDF */}
            <Button
              variant="contained"
              color="primary"
              href={deslinde} // Usa `href` en lugar de `src` para abrir el PDF
              target="_blank" // Abre el PDF en una nueva pestaña
              rel="noopener noreferrer" // Mejora de seguridad al abrir un enlace externo
              sx={{
                marginTop: 2,
                marginRight: 5,
                backgroundColor: "red",
                ":hover": { backgroundColor: "darkred", color: "white" },
              }}
            >
              Deslinde
            </Button>

      <Typography
        variant="h6"
        gutterBottom
        sx={{
          marginTop: 8,
          fontFamily: "Monserat, sans-serif",
          fontWeight: 600,
          fontSize: "1.5rem",
          textTransform: "uppercase",
          textDecoration: "underline",
          textDecorationColor: "red",
          textDecorationThickness: "2px",
        }}
      >
        Inscripciones
      </Typography>

      <Grid item xs={12} sm={12}>
  <Grid container spacing={4} justifyContent="center">
    {periods.length > 0 ? (
      <>
        {periods.some((period) => period.periodName !== "Adaptado") && (
          <>
            {periods
              .filter((period) => period.periodName !== "Adaptado")
              .map((period) => (
                <Grid item xs={12} sm={4} md={3} key={period.id}>
                  <Card
                    variant="outlined"
                    sx={{
                      maxWidth: 300,
                      transition: "0.2s",
                      boxShadow: "0px 1px 20px rgba(100, 0, 0, 100)",
                      borderRadius: 3,
                      "&:hover": {
                        transform: "scale(1.05)",
                      },
                    }}
                  >
                    <CardContent>
                      <Typography variant="h2" gutterBottom>
                        {period.distance}
                      </Typography>
                      <Typography variant="h7" gutterBottom>
                        {period.periodName}
                      </Typography>
                      <Typography variant="h5">${period.price}</Typography>
                    </CardContent>
                    <Box sx={{ textAlign: "center", paddingBottom: 2 }}>
                      <Button
                        variant="contained"
                        color="error"
                        onClick={() =>
                          handleInscribirse(
                            "Maraton De San Rafael 2025",
                            period.distance,
                            period.periodName,
                            period.price,
                            img1
                          )
                        }
                        disabled={isSoldOut}
                      >
                        Inscribirse
                      </Button>
                      {isSoldOut && (
                        <Box
                          sx={{
                            textAlign: "center",
                            backgroundColor: "rgba(255, 0, 0, 0.7)",
                            color: "white",
                            padding: 1,
                          }}
                        >
                          <Typography variant="h6">Cupos agotados</Typography>
                          <Typography variant="h6" sx={{ fontSize: "0.8rem" }}>
                            Proximamente nuevo periodo
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </Card>
                </Grid>
              ))}
          </>
        )}
      </>
    ) : (
      <Typography
        variant="h6"
        gutterBottom
        sx={{
          marginTop: 8,
          fontFamily: "Monserat, sans-serif",
          fontWeight: 600,
          fontSize: "1.5rem",
          marginLeft: 5,
        }}
      >
        Próximamente disponibles!
      </Typography>
    )}
  </Grid>

        </Grid>
    </Box>
  );
};

export default MaratonSanRafael2025;
