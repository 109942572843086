import { Button, Card, CardActionArea, CardContent, CardMedia, Container, Grid, Typography } from '@mui/material';
import img1 from '../Inscriptions/Info/DesafioPuntaNegra2025/img1.png';
import img2 from '../Inscriptions/Info/Misj2025/img1.png';
import img3 from '../Inscriptions/Info/ValleDelaLuna2025/img1.png';
import img4 from '../Inscriptions/Info/MerloLate2025/img1.png';
import img5 from '../Inscriptions/Info/DeportesRioCuarto2025/img1.png';
import img6 from '../Inscriptions/Info/8-MaratonSanRafael2025/img1.jpg';
import img7 from '../Inscriptions/Info/7-HeroresMalvinas2025/img1.png'

import { useNavigate } from 'react-router-dom';

const Inscripciones = () => {
    const navigate = useNavigate();
  
  return (
    <Container maxWidth="lg" style={{ marginTop: '30px' }}>
      <Typography variant='h3' color="white" align='center' sx={{marginTop: 4, fontFamily: 'Monserat, sans-serif', fontWeight: 600, fontSize: '2rem', textTransform: 'uppercase', textDecoration: 'underline', textDecorationColor: 'red', textDecorationThickness: '2px'}}>Inscripciones abiertas</Typography>

      <Grid container spacing={5} style={{ marginTop: 1 }} align='center'>

            
            

            {/**/}
            <Grid item xs={12} sm={4}>
              <Card
                color="danger"
                variant='outlined'
                style={{ padding: "10px", background: "#000000", color: "white" }}
                sx={{
                  maxWidth: 300,
                  transition: "0.2s",
                  boxShadow: "0px 1px 20px rgba(100, 0, 0, 100)",
                  borderRadius: 3,
                  "&:hover": {
                    transform: "scale(1.05)",
                  }
                }}
              >
                <CardActionArea>
                  <CardMedia component="img" src={img7} style={{ height: 300, objectFit: 'fill' }} />
                  <CardContent>
                  <Typography sx={{marginBottom: 2}}>6 de Abril 2025</Typography>
                  <Button variant="contained" color="error" onClick={() => navigate('/maratonheroesdemalvinas2025')}>Inscribirse</Button>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>

            {/**/}
            <Grid item xs={12} sm={4}>
              <Card
                color="danger"
                variant='outlined'
                style={{ padding: "10px", background: "#000000", color: "white" }}
                sx={{
                  maxWidth: 300,
                  transition: "0.2s",
                  boxShadow: "0px 1px 20px rgba(100, 0, 0, 100)",
                  borderRadius: 3,
                  "&:hover": {
                    transform: "scale(1.05)",
                  }
                }}
              >
                <CardActionArea>
                  <CardMedia component="img" src={img5} style={{ height: 300, objectFit: 'fill' }} />
                  <CardContent>
                  <Typography sx={{marginBottom: 2}}>11 de Mayo 2025</Typography>
                  <Button variant="contained" color="error" onClick={() => navigate('/maratondeportesriocuarto2025')}>Inscribirse</Button>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
            
            {/**/}
            <Grid item xs={12} sm={4}>
              <Card
                color="danger"
                variant='outlined'
                style={{ padding: "10px", background: "#000000", color: "white" }}
                sx={{
                  maxWidth: 300,
                  transition: "0.2s",
                  boxShadow: "0px 1px 20px rgba(100, 0, 0, 100)",
                  borderRadius: 3,
                  "&:hover": {
                    transform: "scale(1.05)",
                  }
                }}
              >
                <CardActionArea>
                  <CardMedia component="img" src={img1} style={{ height: 300, objectFit: 'fill' }} />
                  <CardContent>
                  <Typography sx={{marginBottom: 2}}>22 al 25 de Mayo 2025</Typography>
                  <Button variant="contained" color="error" onClick={() => navigate('/desafiopuntanegra2025')}>Inscribirse</Button>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>

            {/**/}
            <Grid item xs={12} sm={4}>
              <Card
                color="danger"
                variant='outlined'
                style={{ padding: "10px", background: "#000000", color: "white" }}
                sx={{
                  maxWidth: 300,
                  transition: "0.2s",
                  boxShadow: "0px 1px 20px rgba(100, 0, 0, 100)",
                  borderRadius: 3,
                  "&:hover": {
                    transform: "scale(1.05)",
                  }
                }}
              >
                <CardActionArea>
                  <CardMedia component="img" src={img2} style={{ height: 300, objectFit: 'fill' }} />
                  <CardContent>
                  <Typography sx={{marginBottom: 2}}>27 de Julio 2025</Typography>
                  <Button variant="contained" color="error" onClick={() => navigate('/maratoninternacionaldesanjuan2025')}>Inscribirse</Button>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
             
            {/**/}
            <Grid item xs={12} sm={4}>
              <Card
                color="danger"
                variant='outlined'
                style={{ padding: "10px", background: "#000000", color: "white" }}
                sx={{
                  maxWidth: 300,
                  transition: "0.2s",
                  boxShadow: "0px 1px 20px rgba(100, 0, 0, 100)",
                  borderRadius: 3,
                  "&:hover": {
                    transform: "scale(1.05)",
                  }
                }}
              >
                <CardActionArea>
                  <CardMedia component="img" src={img6} style={{ height: 300, objectFit: 'fill' }} />
                  <CardContent>
                  <Typography sx={{marginBottom: 2}}>31 de Agosto 2025</Typography>
                  <Button variant="contained" color="error" onClick={() => navigate('/maratondesanrafael2025')}>Inscribirse</Button>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>

            {/**/}
            <Grid item xs={12} sm={4}>
              <Card
                color="danger"
                variant='outlined'
                style={{ padding: "10px", background: "#000000", color: "white" }}
                sx={{
                  maxWidth: 300,
                  transition: "0.2s",
                  boxShadow: "0px 1px 20px rgba(100, 0, 0, 100)",
                  borderRadius: 3,
                  "&:hover": {
                    transform: "scale(1.05)",
                  }
                }}
              >
                <CardActionArea>
                  <CardMedia component="img" src={img4} style={{ height: 300, objectFit: 'fill' }} />
                  <CardContent>
                  <Typography sx={{marginBottom: 2}}>27 de Septiembre 2025</Typography>
                  <Button variant="contained" color="error" onClick={() => navigate('/merlolate2025')}>Inscribirse</Button>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
            
            {/**/}
            <Grid item xs={12} sm={4}>
              <Card
                color="danger"
                variant='outlined'
                style={{ padding: "10px", background: "#000000", color: "white" }}
                sx={{
                  maxWidth: 300,
                  transition: "0.2s",
                  boxShadow: "0px 1px 20px rgba(100, 0, 0, 100)",
                  borderRadius: 3,
                  "&:hover": {
                    transform: "scale(1.05)",
                  }
                }}
              >
                <CardActionArea>
                  <CardMedia component="img" src={img3} style={{ height: 300, objectFit: 'fill' }} />
                  <CardContent>
                  <Typography sx={{marginBottom: 2}}>01 de Noviembre 2025</Typography>
                  <Button variant="contained" color="error" onClick={() => navigate('/desafiovalledelaluna2025')}>Inscribirse</Button>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
      </Grid>
    </Container>
  );
};

export default Inscripciones;
