import React, { useState, useEffect } from 'react';
import { TextField, Box, Typography, Grid, Select, MenuItem, InputLabel, FormControl, Button, FormControlLabel, Checkbox, Card, CardContent } from '@mui/material';
import { useParams, useLocation } from 'react-router-dom';
import axios from 'axios';
import { initMercadoPago, Wallet } from '@mercadopago/sdk-react';
import { v4 as uuidv4 } from 'uuid';
import config from '../../../../config/config.js'
import talles from '../8-MaratonSanRafael2025/TablaDeTalles.png'
import mercadoPagoLogo from '../../../../images/ImgInscripciones/MercadoPagoLogo.png';
/*import paypalLogo from '../../../../images/ImgInscripciones/PayPalLogo.png';
import efectivoLogo from '../../../../images/ImgInscripciones/EfectivoLogo.png';*/
import Swal from 'sweetalert2';

const Forms = () => {
  const { raceName } = useParams();
  const { state } = useLocation();
  const { distance, price, periodoInscripcion, priceTax, image } = state || {};
  const formattedRaceName = raceName.toLowerCase().replace(/\s+/g, '_');
  const [gender, setGender] = useState('');
  const [discountCode, setDiscountCode] = useState('');
  const [discountedPrice, setDiscountedPrice] = useState(null);
  const [preferenceId] = useState(null);
  const [email, setEmail] = useState('');
  const [shirtSize, setShirtSize] = useState('');
  const [Procedencia, setProcedencia] = useState('');
  const [message, setMessage] = useState('');
  const [categoria, setCategoria] = useState('');
  const [paymentOption, setPaymentOption] = useState("100%");
  const [selectedTransport, setSelectedTransport] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState("Mercado Pago");
  const transportPrice = 3000;
  const paymentMethods = [
      { name: 'Mercado Pago', logo: mercadoPagoLogo },
      /*{ name: 'PayPal', logo: paypalLogo },*/
      /*{ name: 'Pago en efectivo', logo: efectivoLogo },*/
    ];
    const paypalButtons = {
      "12K": "WTK794WWR86QL",
      "42K": "2SJQ4Y9ZZW954", // ID de pago de 42K
      "21K": "D52H6X37T8MA4", // ID de pago de 21K
    };
  
  // Inicializa Mercado Pago solo cuando el componente se monta
  useEffect(() => {
    initMercadoPago('APP_USR-72879720-3f27-4cb1-b4fd-38f4ba5edaea', { locale: 'es-AR' });
    window.scrollTo(0, 0);
  }, []); // El array vacío asegura que se ejecuta solo una vez

  const createPreference = async (formId) => {
    try {
      const priceToPay = calculatePrice();
      const payerEmail = email; // Usa el email capturado del estado
      const applicationFee = price * 0.02;
      const sellerAmount = priceToPay - applicationFee;
    
      const response = await axios.post(`${config.INSCRIPTION_URL}/create_preference`, {
        title: `Inscripción - ${distance} - ${periodoInscripcion} - ${raceName}`,
        quantity: 1,
        price: priceToPay,
        formId,
        payerEmail,      // Añadir el email del pagador
        applicationFee,  // Añadir la comisión del marketplace
        sellerAmount,
        raceName: formattedRaceName,
      });
      const { initPoint } = response.data;
    return initPoint;
    } catch (error) {
      console.error('Error al crear la preferencia:', error);
      alert("Hubo un error al procesar el pago. Intenta nuevamente.");
      return null;
    }
  };

  const handleDiscountCodeChange = (event) => {
    setDiscountCode(event.target.value);
  };

const applyDiscount = async () => {
  if (!discountCode) {
    alert("Por favor, ingresa un código de descuento.");
    return;
  }

  try {
    // Llamada al backend para validar el código
    const response = await axios.get(`${config.INSCRIPTION_URL}/api/discounts`, {
      params: { raceName: formattedRaceName, code: discountCode },
    });

    const { discountType, discountValue, expirationDate, usageCount, cantidad } = response.data;

    // Validar que el código no haya expirado
    const today = new Date();
    if (new Date(expirationDate) < today) {
      alert("El código de descuento ha expirado.");
      return;
    }

    // Validar si el código alcanzó su límite de usos
    if (usageCount >= cantidad) {
      alert("El código de descuento ya no está disponible.");
      return;
    }

    // Calcular el precio total antes de aplicar el descuento
    let newPrice = price + priceTax; 
    
    if (discountType === "percentage") {
      const discountFactor = 1 - discountValue / 100; // Convierte el porcentaje en un factor de descuento
      newPrice = price * discountFactor;
    } else if (discountType === "amount") {
      newPrice = price - discountValue; // Resta el monto fijo
    }

    // Asegurarse de que el precio no sea negativo (o inválido)
    newPrice = Math.max(newPrice, 0); // Si es menor a 0, lo fuerza a 0

    // Verifica y actualiza el estado
    if (newPrice === 0) {
      console.log("El precio con descuento es 0.");
    }

    setDiscountedPrice(newPrice);
    try {
      await axios.post(`${config.INSCRIPTION_URL}/api/discounts/use`, {
        raceName: formattedRaceName,
        code: discountCode,
      });
      setMessage('Codigo de descuento aplicado');
      console.log("Uso del código registrado con éxito.");
    } catch (error) {
      console.error("Error al registrar el uso del código de descuento:", error);
    }
  } catch (error) {
    console.error("Error al validar el código de descuento:", error);
    if (error.response && error.response.status === 404) {
      alert("El código de descuento no es válido.");
    } else {
      alert("El código de descuento ya no está disponible.");
    }
  }
};

  const handleGenderChange = (event) => {
    setGender(event.target.value);
  };

  const handleShirtSizeChange = (event) => {
    setShirtSize(event.target.value); // Maneja el cambio de tallaje
    console.log("Talle seleccionado:", event.target.value);
  };

  const handleCategoriaChange = (event) => {
    setCategoria(event.target.value);
    console.log("Categoria:", event.target.value);
  };

  const handleFormSubmit = async () => {
    const formId = uuidv4();
    const finalPrice = calculatePrice();
    const formattedDate = getFormattedDate();
    const adjustedPriceTax = paymentOption === "1° cuota" ? (priceTax * 1.15) / 2 : priceTax;
    const isFirstInstallment = paymentOption === "1° cuota";
    const formData = {
      formId,
      FirstName: document.getElementById('first_name').value,
      LastName: document.getElementById('last_name').value,
      DNI: document.getElementById('document_number').value,
      gender,
      DateOfBirth: formattedDate,
      age: document.getElementById('age').value,
      categoria,
      email: document.getElementById('email').value,
      phone: document.getElementById('phone').value,
      Nacion: document.getElementById('Nation').value,
      Procedencia,
      talle: shirtSize,
      running_team: document.getElementById('Team').value,
      distance,
      transporte: selectedTransport ? "si" : "",
      price,
      discount_code: discountCode,
      discounted_price: discountedPrice || price,
      priceTax: adjustedPriceTax,
      paymentOption,
      payment_price: finalPrice,
      payment_status: selectedPayment === "Pago en efectivo" ? "efectivo" : finalPrice === 0 ? "free" : null,
      pending_price: isFirstInstallment ? Math.ceil(((discountedPrice || price) * 1.15) / 2000) * 1000 : 0,
      periodoInscripcion,
      raceName: formattedRaceName,
      contacto_emergencia: document.getElementById('contacto_emergencia').value,
      cel_emergencia: document.getElementById('cel_emergencia').value,
      obra_social: document.getElementById('obra_social').value,
      RH: document.getElementById('RH').value,
      peso: document.getElementById('peso').value,
      frecuencia_cardiaca: document.getElementById('frecuencia_cardiaca').value,
      medicamento: document.getElementById('medicamento').value,
      alergia: document.getElementById('alergia').value,
      enfermedad_cardiaca: document.getElementById('enfermedad_cardiaca').value,
      enfermedad_cronica: document.getElementById('enfermedad_cronica').value,
    };
    setEmail(formData.email); // Establece el correo electrónico para la preferencia
    try {
      await axios.post(`${config.INSCRIPTION_URL}/submitform`, formData);
      console.log('Formulario enviado correctamente');
      return formId;
    } catch (error) {
      console.error('Error al enviar el formulario:', error);
      return null;
    }
  };

  const handleButtonClick = async () => {
    // Primero envío los datos del formulario y obtengo el formId
    const formId = await handleFormSubmit();
  
    if (!formId) {
      console.error("Error al enviar el formulario");
      return;
    }
  
    const finalPrice = calculatePrice(); // Calculamos el precio final
  
    // Si el precio final es 0, se finaliza la inscripción sin pasar por Mercado Pago
    if (finalPrice === 0) {
      Swal.fire({
        title: "Inscripción exitosa",
        text: "Tu inscripción ha sido registrada correctamente.",
        icon: "success",
        confirmButtonColor: "#d33",
        confirmButtonText: "Aceptar",
      }).then(() => {
      window.location.href = "https://esfuerzodeportivosr.com.ar/";
      });
      return;
    }
  
    // Si el usuario seleccionó "Pago en efectivo", también finaliza sin Mercado Pago
    if (selectedPayment === "Pago en efectivo") {
      Swal.fire({
        title: "Preinscripción realizada",
        text: "Completa el pago en Rustik San Juan dentro de las proximas 48 horas.",
        icon: "success",
        confirmButtonColor: "#d33",
        confirmButtonText: "Aceptar",
        footer: "NOTA: Exclusivo residentes de San Juan",
      }).then(() => {
        window.location.href = "https://esfuerzodeportivosr.com.ar/";
      });
      return;
    }
  
    // Si el usuario seleccionó PayPal, redirigir al pago de PayPal
    if (selectedPayment === "PayPal") {
      const paypalPaymentId = paypalButtons[distance]; // Obtener el ID de pago según la distancia
      if (paypalPaymentId) {
        window.location.href = `https://www.paypal.com/ncp/payment/${paypalPaymentId}`;
        return;
      } else {
        console.error("No se encontró un ID de pago para la distancia seleccionada.");
        alert("Error al procesar el pago con PayPal.");
        return;
      }
    }
  
    // Si ninguna de las condiciones anteriores se cumple, se crea la preferencia de Mercado Pago
    const initPoint = await createPreference(formId);
    if (initPoint) {
      window.location.href = initPoint; // Redirige al usuario a pagar en Mercado Pago
    }
  };

  const calculatePrice = () => {
    const basePrice = parseFloat(discountedPrice || price);
    const totalPrice = (basePrice + parseFloat(priceTax)).toFixed(2);
  
    if (paymentOption === "100%") {
      // Pago total (sin recargo)
      return totalPrice;
    } else if (paymentOption === "1° cuota") {
      // Pago 50% con el 15% de recargo dividido en dos pagos
      const totalWithSurcharge = totalPrice * 1.15; // Añadir el 15% de recargo
      return (totalWithSurcharge / 2).toFixed(2); // Dividir en dos pagos iguales
    }
  
    return "0.00"; // Valor por defecto en caso de no coincidir
  }; 
  
  const handleProcedenciaChange = (event) => {
    setProcedencia(event.target.value);
  };

  const handlePaymentChange = (event) => {
    setSelectedPayment(event.target.value);
  };

  const handleTransportChange = (event) => {
    setSelectedTransport(event.target.checked);
  };

  const [birthDate, setBirthDate] = useState({
      day: '',
      month: '',
      year: '',
    });
    
    const handleDateChange = (e, type) => {
      const value = e.target.value;
      setBirthDate((prev) => ({ ...prev, [type]: value }));
    };
    
    // Cuando envíes el formulario, convierte los valores a un formato válido (e.g., "YYYY-MM-DD"):
    const getFormattedDate = () => {
      const { day, month, year } = birthDate;
      if (day && month && year) {
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
      }
      return '';
    };  
  
  return (
    <Box
      sx={{
        backgroundColor: '#f5f5f5',
        padding: { xs: 2, md: 4},
        maxWidth: '100%',
        margin: 'auto',
        fontFamily: 'Arial, sans-serif',
      }}
    >
      
      <Box
      sx={{
        backgroundColor: '#f5f5f5',
        width: '100%',
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' }, // Columna en pantallas pequeñas, fila en pantallas grandes
        justifyContent: 'center',
        fontFamily: 'Arial, sans-serif',
      }}
    >
      <Box
      sx={{
        backgroundColor: '#f5f5f5',
        padding: { xs: 2, md: 4},
        borderRadius: 2,
        maxWidth: { xs: '100%', md: '80%' },
        fontFamily: 'Arial, sans-serif',
        boxShadow: "0px 1px 10px black",
        marginBottom: { xs: 2, md: 0 },
      }}
    >
      <Box
        component="img"
        src={image}
        alt="Banner"
        sx={{
          width: '100%',
          borderRadius: 2,
          marginBottom: 3,
          maxHeight: '300px',
          boxShadow: "0px 1px 10px black",
        }}
      />
      <Typography variant="h6" gutterBottom sx={{ fontFamily: 'Monserat, sans-serif', fontWeight: 600, fontSize: '1.5rem', textTransform: 'uppercase', textDecoration: 'underline', textDecorationColor: 'red', textDecorationThickness: '2px'}}>
        Datos del corredor
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            id="first_name"
            label="Nombre"
            variant="outlined"
            sx={{
              '& .MuiInputLabel-root': { color: '#333' },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#ccc',
                },
                '&:hover fieldset': {
                  borderColor: '#666',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#1976d2',
                },
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            id="last_name"
            label="Apellido"
            variant="outlined"
            sx={{
              '& .MuiInputLabel-root': { color: '#333' },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#ccc',
                },
                '&:hover fieldset': {
                  borderColor: '#666',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#1976d2',
                },
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            id="document_number"
            label="Número de Documento"
            variant="outlined"
            sx={{
              '& .MuiInputLabel-root': { color: '#333' },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#ccc',
                },
                '&:hover fieldset': {
                  borderColor: '#666',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#1976d2',
                },
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth required variant="outlined">
            <InputLabel id="gender-label">Género</InputLabel>
            <Select
              id="gender"
              labelId="gender-label"
              value={gender}
              onChange={handleGenderChange}
              label="Género"
              required
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#ccc',
                  },
                  '&:hover fieldset': {
                    borderColor: '#666',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#1976d2',
                  },
                },
              }}
            >
              <MenuItem value="masculino">Masculino</MenuItem>
              <MenuItem value="femenino">Femenino</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
                <Box display="flex" alignItems="center" gap={2}>
                  <Typography>Fecha de Nacimiento</Typography> 
                  <TextField
                    required
                    id="birth_day"
                    label="DD"
                    variant="outlined"
                    type="number"
                    value={birthDate.day || ""}
                    error={birthDate.day && (birthDate.day < 1 || birthDate.day > 31)}
                    helperText={
                     birthDate.day && (birthDate.day < 1 || birthDate.day > 31)
                      ? "Entre 1 y 31"
                      : ""
                    }
                    onChange={(e) => handleDateChange(e, 'day')}
              sx={{
                width: '80px',
                '& .MuiInputLabel-root': { color: '#333' },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': { borderColor: '#ccc' },
                  '&:hover fieldset': { borderColor: '#666' },
                  '&.Mui-focused fieldset': { borderColor: '#1976d2' },
                },
              }}
            />
            <TextField
              required
              id="birth_month"
              label="MM"
              variant="outlined"
              type="number"
              value={birthDate.month || ""}
              error={birthDate.month && (birthDate.month < 1 || birthDate.month > 12)}
              helperText={
                birthDate.month && (birthDate.month < 1 || birthDate.month > 12)
                  ? "Entre 1 y 12"
                  : ""
              }
              onChange={(e) => handleDateChange(e, 'month')}
              sx={{
                width: '80px',
                '& .MuiInputLabel-root': { color: '#333' },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': { borderColor: '#ccc' },
                  '&:hover fieldset': { borderColor: '#666' },
                  '&.Mui-focused fieldset': { borderColor: '#1976d2' },
                },
              }}
            />
            <TextField
              required
              id="birth_year"
              label="AAAA"
              variant="outlined"
              type="number"
              value={birthDate.year || ""}
              error={
                birthDate.year &&
                (birthDate.year < 1900 || birthDate.year > new Date().getFullYear())
              }
              helperText={
                birthDate.year &&
                (birthDate.year < 1900 || birthDate.year > new Date().getFullYear())
                  ? `Entre 1900 y ${new Date().getFullYear()}`
                  : ""
              }
              onChange={(e) => handleDateChange(e, 'year')}
              sx={{
                width: '100px',
                '& .MuiInputLabel-root': { color: '#333' },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': { borderColor: '#ccc' },
                  '&:hover fieldset': { borderColor: '#666' },
                  '&.Mui-focused fieldset': { borderColor: '#1976d2' },
                },
              }}
            />
            </Box>
                  </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            id="age"
            label="Edad al día de la carrera"
            variant="outlined"
            type="number"
            sx={{
              '& .MuiInputLabel-root': { color: '#333' },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#ccc',
                },
                '&:hover fieldset': {
                  borderColor: '#666',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#1976d2',
                },
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
                      <FormControl fullWidth required variant="outlined">
                        <InputLabel id="categoria-label">Categoria</InputLabel>
                        <Select
                          id='categoria'
                          name="categoria"
                          value={categoria}
                          onChange={handleCategoriaChange}
                          label="Categoria"
                          required
                          MenuProps={{
                            PaperProps: {
                              style: {
                                maxHeight: 300, // Altura máxima de la lista desplegable
                                overflow: 'auto', // Habilita el desplazamiento
                              },
                            },
                          }}
                          sx={{
                            '& .MuiInputLabel-root': { color: '#333' },
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor: '#ccc',
                              },
                              '&:hover fieldset': {
                                borderColor: '#666',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: '#1976d2',
                              },
                            },
                          }}
                        >
                          <MenuItem value="POR EDADES">POR EDADES</MenuItem>
                          <MenuItem value="Silla de ruedas">Silla de ruedas</MenuItem>
                          <MenuItem value="No videne">No videne</MenuItem>
                          <MenuItem value="Asistido">Asistido</MenuItem>
                          <MenuItem value="Otras discapacidades">Otras discapacidades</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            id="email"
            label="Correo Electrónico"
            variant="outlined"
            type="email"
            sx={{
              '& .MuiInputLabel-root': { color: '#333' },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#ccc',
                },
                '&:hover fieldset': {
                  borderColor: '#666',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#1976d2',
                },
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            id="phone"
            label="Teléfono"
            variant="outlined"
            type="tel"
            sx={{
              '& .MuiInputLabel-root': { color: '#333' },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#ccc',
                },
                '&:hover fieldset': {
                  borderColor: '#666',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#1976d2',
                },
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            id="Nation"
            label="Nacionalidad"
            variant="outlined"
            sx={{
              '& .MuiInputLabel-root': { color: '#333' },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#ccc',
                },
                '&:hover fieldset': {
                  borderColor: '#666',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#1976d2',
                },
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth required variant="outlined">
            <InputLabel id="province-label">Provincia</InputLabel>
            <Select
              id="province"
              labelId="province-label"
              value={Procedencia}
              onChange={handleProcedenciaChange}
              label="Provincia"
              required
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 300, // Altura máxima de la lista desplegable
                    overflow: 'auto', // Habilita el desplazamiento
                  },
                },
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#ccc',
                  },
                  '&:hover fieldset': {
                    borderColor: '#666',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#1976d2',
                  },
                },
              }}
            >
              {[
                'Buenos Aires', 'CABA', 'Catamarca', 'Chaco', 'Chubut',
                'Córdoba', 'Corrientes', 'Entre Ríos', 'Formosa', 'Jujuy',
                'La Pampa', 'La Rioja', 'Mendoza', 'Misiones', 'Neuquén',
                'Río Negro', 'Salta', 'San Juan', 'San Luis', 'Santa Cruz',
                'Santa Fe', 'Santiago del Estero', 'Tierra del Fuego', 'Tucumán', 'Otra'
              ].map((province) => (
                <MenuItem key={province} value={province}>
                  {province}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
              <FormControl fullWidth required variant="outlined">
                <InputLabel id="shirt-size-label">Talle de remera</InputLabel>
                <Select
                  id='talle'
                  name="talle"
                  value={shirtSize}
                  onChange={handleShirtSizeChange}
                  label="Talle de remera"
                  required
                  sx={{
                    '& .MuiInputLabel-root': { color: '#333' },
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: '#ccc',
                      },
                      '&:hover fieldset': {
                        borderColor: '#666',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#1976d2',
                      },
                    },
                  }}
                >
                  <MenuItem value="XS">XXS</MenuItem>
                  <MenuItem value="XS">XS</MenuItem>
                  <MenuItem value="S">S</MenuItem>
                  <MenuItem value="M">M</MenuItem>
                  <MenuItem value="L">L</MenuItem>
                  <MenuItem value="XL">XL</MenuItem>
                  <MenuItem value="XXL">XXL</MenuItem>
                  <MenuItem value="XXL">XXXL</MenuItem>
                </Select>
                <a
      href={talles}
      target="_blank"
      rel="noopener noreferrer"
      style={{
        textDecoration: 'none',
        color: 'red',
        fontSize: '0.875rem',
        display: 'inline-block',
        textAlign: 'left',
      }}
    >
      Tabla de talles
    </a>
              </FormControl>
            </Grid>
            
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            id="Team"
            label="Running Team"
            variant="outlined"
            sx={{
              '& .MuiInputLabel-root': { color: '#333' },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#ccc',
                },
                '&:hover fieldset': {
                  borderColor: '#666',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#1976d2',
                },
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            id="contacto_emergencia"
            label="Nombre contacto de emergencia"
            variant="outlined"
            required
            sx={{
              '& .MuiInputLabel-root': { color: '#333' },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#ccc',
                },
                '&:hover fieldset': {
                  borderColor: '#666',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#1976d2',
                },
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            id="cel_emergencia"
            label="Telefono de emergencia"
            variant="outlined"
            required
            sx={{
              '& .MuiInputLabel-root': { color: '#333' },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#ccc',
                },
                '&:hover fieldset': {
                  borderColor: '#666',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#1976d2',
                },
              },
            }}
          />
        </Grid>
      </Grid>
      <Typography variant="h6" gutterBottom sx={{marginTop: 4, fontFamily: 'Monserat, sans-serif', fontWeight: 600, fontSize: '1.5rem', textTransform: 'uppercase', textDecoration: 'underline', textDecorationColor: 'red', textDecorationThickness: '2px'}}>
        Ficha medica
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            id="obra_social"
            label="Obra social"
            variant="outlined"
            sx={{
              '& .MuiInputLabel-root': { color: '#333' },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#ccc',
                },
                '&:hover fieldset': {
                  borderColor: '#666',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#1976d2',
                },
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            id="RH"
            label="Grupo y factor sanguíneo"
            variant="outlined"
            sx={{
              '& .MuiInputLabel-root': { color: '#333' },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#ccc',
                },
                '&:hover fieldset': {
                  borderColor: '#666',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#1976d2',
                },
              },
            }}
          />
        </Grid>  
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            id="peso"
            label="Peso"
            variant="outlined"
            sx={{
              '& .MuiInputLabel-root': { color: '#333' },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#ccc',
                },
                '&:hover fieldset': {
                  borderColor: '#666',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#1976d2',
                },
              },
            }}
          />
        </Grid>  
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            id="frecuencia_cardiaca"
            label="Frecuencia cardiaca"
            variant="outlined"
            sx={{
              '& .MuiInputLabel-root': { color: '#333' },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#ccc',
                },
                '&:hover fieldset': {
                  borderColor: '#666',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#1976d2',
                },
              },
            }}
          />
        </Grid>  
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            id="medicamento"
            label="Tomas algun medicamento de forma habitual?"
            variant="outlined"
            sx={{
              '& .MuiInputLabel-root': { color: '#333' },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#ccc',
                },
                '&:hover fieldset': {
                  borderColor: '#666',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#1976d2',
                },
              },
            }}
          />
        </Grid>  
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            id="alergia"
            label="Sos alérgico a algo?"
            variant="outlined"
            sx={{
              '& .MuiInputLabel-root': { color: '#333' },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#ccc',
                },
                '&:hover fieldset': {
                  borderColor: '#666',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#1976d2',
                },
              },
            }}
          />
        </Grid>  
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            id="enfermedad_cardiaca"
            label="Padeces alguna enfermedad cardiaca?"
            variant="outlined"
            sx={{
              '& .MuiInputLabel-root': { color: '#333' },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#ccc',
                },
                '&:hover fieldset': {
                  borderColor: '#666',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#1976d2',
                },
              },
            }}
          />
        </Grid>  
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            id="enfermedad_cronica"
            label="Padeces alguna enfermedad crónica?"
            variant="outlined"
            sx={{
              '& .MuiInputLabel-root': { color: '#333' },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#ccc',
                },
                '&:hover fieldset': {
                  borderColor: '#666',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#1976d2',
                },
              },
            }}
          />
        </Grid>  
        </Grid>

        <Typography
        variant="h5"
        gutterBottom
        sx={{
          marginTop: 5,
          fontFamily: "Monserat, sans-serif",
          fontWeight: 600,
          fontSize: "1.5rem",
          textTransform: "uppercase",
          textDecoration: "underline",
          textDecorationColor: "red",
          textDecorationThickness: "2px",
        }}
      >
        Transporte
      </Typography>

        <Grid item xs={12} sm={6} sx={{ display: "flex" }}>
          <Card variant="outlined" sx={{width: "100%", display: "flex", flexDirection: "column" }}>
            <CardContent sx={{ flexGrow: 1, background: '#f5f5f5' }}>
              <Typography variant="h6" gutterBottom>
              Puedes incluir en tu inscripción el transporte al lugar de la largada, disponible para 42K, 21K y 10K.
              </Typography>
              <Grid container direction="column">
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                    checked={selectedTransport}
                    onChange={handleTransportChange}
                      sx={{ color: "red", "&.Mui-checked": { color: "red" } }}
                    />
                  }
                  label={
                    <Box display="flex" alignItems="center">
                      <strong>$3.000</strong>
                    </Box>
                  }
                />
              </Grid>
            </Grid>
          </CardContent>
          </Card>
          </Grid>

        <Typography variant="h5" gutterBottom sx={{ marginTop: 5, fontFamily: 'Monserat, sans-serif', fontWeight: 600, fontSize: '1.5rem', textTransform: 'uppercase', textDecoration: 'underline', textDecorationColor: 'red', textDecorationThickness: '2px'}}>Opciones de pago
        </Typography>
        <Typography variant="h8" gutterBottom sx={{ marginTop: 5 }}>Podes elegir PAGO TOTAL y abonar el 100% del valor de la inscripción o podes elegir PAGO EN DOS CUOTAS (se aplica un recargo del 15%) abonas la primera cuota ahora y la segunda cuota en EFECTIVO el día de la acreditación y retiro de Kit.
        </Typography>
                  <FormControl fullWidth
                  sx={{
                    marginTop: 2,
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: '#ccc',
                      },
                      '&:hover fieldset': {
                        borderColor: '#666',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#1976d2',
                      },
                    },
                  }}>
                  <Select
                    labelId="payment-option-label"
                    value={paymentOption}
                    onChange={(e) => setPaymentOption(e.target.value)}
                  >
                    <MenuItem value="100%">Pago Total</MenuItem>
                    <MenuItem value="1° cuota">Pago en dos cuotas</MenuItem>
                  </Select>
                  </FormControl>

              <Typography variant="h6" gutterBottom sx={{marginTop: 6, marginBottom: 4, fontFamily: 'Monserat, sans-serif', fontWeight: 600,     fontSize: '1.3rem', textTransform: 'uppercase', textDecoration: 'underline', textDecorationColor: 'red', textDecorationThickness: '2px'}}>
              Pago de la inscripción en {raceName}
              </Typography>
          <Grid container spacing={2} alignItems="stretch">
          <Grid item xs={12} sm={6} sx={{ display: "flex" }}>
          <Card variant="outlined"
                sx={{transition: "0.2s", boxShadow: "0px 1px 10px black", borderRadius: 1, width: "100%", display: "flex", flexDirection: "column" }}>
            <CardContent sx={{ flexGrow: 1 }}>
              <Typography variant="h6" gutterBottom>
                {distance} - {periodoInscripcion}
              </Typography>
            <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', marginTop: 2 }}>
                          Precio {paymentOption}: ${paymentOption === "1° cuota" ? (((discountedPrice || price) * 1.15) / 2).toFixed(2) : (discountedPrice || price).toFixed(2)}
                        </Typography>
          
                        <Typography variant="h7" gutterBottom sx={{ fontWeight: 'bold' }}>
                          Precio del servicio: ${paymentOption === "1° cuota" ? ((priceTax * 1.15) / 2).toFixed(2) : priceTax}
                        </Typography>

                        {selectedTransport && (
                        <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', color: 'red' }}>
                        Transporte: ${transportPrice}
                        </Typography>
                        )}
          
                        <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', marginTop: 2 }}>
                          Precio Total: $
                          {paymentOption === "1° cuota"
                          ? (((Number(discountedPrice) || Number(price)) * 1.15) / 2 + ((Number(priceTax || 0) / 2) * 1.15) +
                          (selectedTransport ? transportPrice : 0)).toFixed(2)
                          : ((Number(discountedPrice) || Number(price)) + Number(priceTax || 0) + 
                          (selectedTransport ? transportPrice : 0)).toFixed(2)}
                        </Typography>
          
          
                    <Typography variant="body2" gutterBottom sx={{ fontSize: '0.8rem' }}>
                    {paymentOption} del valor total de la inscripción + comisión {selectedTransport && (
                        <Typography variant="body2" gutterBottom sx={{ fontSize: '0.8rem' }}>
                        y transporte
                        </Typography>
                        )}
                    </Typography>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={8}>
              <TextField
                fullWidth
                id="discountCode"
                label="Código de descuento"
                variant="outlined"
                value={discountCode}
                onChange={handleDiscountCodeChange}
                sx={{
                  height: '35px', // Altura igual a la del botón
                  '& .MuiInputLabel-root': {
                    color: '#333',
                    marginLeft: '14px',
                    top: '50%', // Centra el label verticalmente
                    transform: 'translateY(-50%)', // Ajuste preciso
                    transition: 'opacity 0.2s ease-in-out',
                  },
                  
                  '& .MuiOutlinedInput-root': {
                    height: '100%', // Hace que el campo tenga la misma altura que el botón
                    display: 'flex',
                    alignItems: 'center',
                  },
                  
                 '& .MuiInputLabel-root.MuiFormLabel-filled': {
                    opacity: 0,
                  },
                }}
              />
            </Grid>
            <Grid item xs={4}>
            <Button
              fullWidth
              variant="contained"
              onClick={applyDiscount}
              sx={{
                height: '35px',
                backgroundColor: 'red',
                color: 'white',
                '&:hover': {
                  backgroundColor: 'darkred',
                },
              }}
            >
              Aplicar
            </Button>
            </Grid>
          </Grid>
          {message}
          </CardContent>
          </Card>
          </Grid>

          <Grid item xs={12} sm={6} sx={{ display: "flex" }}>
          <Card variant="outlined" sx={{transition: "0.2s", boxShadow: "0px 1px 10px black", borderRadius: 1, width: "100%", display: "flex", flexDirection: "column" }}>
            <CardContent sx={{ flexGrow: 1 }}>
              <Typography variant="h6" gutterBottom>
                Medio de pago
              </Typography>
              <Grid container direction="column">
              {paymentMethods.map((method) => (
                <Grid item key={method.name}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedPayment === method.name}
                        onChange={handlePaymentChange}
                        value={method.name}
                        name="paymentMethod"
                        defaultChecked={method.name === "Mercado Pago"}
                        sx={{ color: "red", "&.Mui-checked": { color: "red" } }}
                      />
                    }
                    label={
                      <Box display="flex" alignItems="center">
                        <img src={method.logo} alt={method.name} width="100" style={{ marginRight: 10 }} />
                      </Box>
                    }
                  />
                </Grid>
              ))}
              
              </Grid>
            </CardContent>
          </Card>
          </Grid>
          </Grid>

          <Button
            fullWidth
            variant="contained"
            onClick={handleButtonClick}
            sx={{
              marginTop: 3,
              backgroundColor: 'red',
              color: 'white',
              '&:hover': {
                backgroundColor: 'darkred',
              },
            }}
          >
            Finalizar
          </Button> 
          {preferenceId && (
            <Wallet
              initialization={{ preferenceId: preferenceId }}
              customization={{ texts: { valueProp: 'smart_option' } }}
            />
          )}
        </Box> 
      </Box>
    </Box>
  );
};

export default Forms;
