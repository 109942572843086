import React, { useState } from 'react';
import Menu from './Menu.jsx';
import config from '../../config/config.js';

const Login = () => {
    const [token, setToken] = useState(localStorage.getItem("token") || null);
    const [usu, setUsu] = useState("");
    const [pas, setPas] = useState("");

    async function iniciarSesion(e) {
        e.preventDefault();

        const response = await fetch((`${config.API_URL}/login`), {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ username: usu, password: pas }),
        });
    
        const data = await response.json();
        if (response.ok) {
            setToken(data.token);
            localStorage.setItem("token", data.token);
        } else {
            alert(data.error);
        }
    }

    return (
        <div className="container bg-dark text-white" style={{ padding: 20, borderRadius: '1rem', maxWidth: '800px' }}>
            {!token ? (
                <form onSubmit={iniciarSesion}>
                    <h1 style={{ color: "white", textAlign: "center" }}>LOGIN</h1>
                    <label><strong>Username</strong></label>
                    <input type="text" className="form-control" onChange={(e) => setUsu(e.target.value)} required />
                    <br />
                    <label><strong>Password</strong></label>
                    <input type="password" className="form-control" onChange={(e) => setPas(e.target.value)} required />
                    <br />
                    <button type="submit" className="btn btn-primary">Login</button>
                </form>
            ) : (
                <Menu />
            )}
        </div>
    );
}

export default Login;
