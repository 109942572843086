import React, {useState, useEffect} from "react";
import { Box, Typography, Grid, Card, CardContent, Button, List, ListItemIcon, ListItemText } from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import config from "../../../../config/config";
import img1 from './banner.png';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

const PaypalInteg = () => {
  const navigate = useNavigate();
  const [periods, setPeriods] = useState([]);
  const isSoldOut = false;
 
  // Obtener los periodos de inscripción desde el backend
  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchPeriods = async () => {
      try {
        const response = await axios.get(`${config.INSCRIPTION_URL}/api/inscription-periods`, {
          params: { raceName: "paypalinteg" }, // Filtra por nombre de la carrera
        });
        // Filtrar los periodos que son válidos para la fecha actual
        const currentDate = new Date();
        const filteredPeriods = response.data.periods.filter((period) => {
          const startDate = new Date(period.startDate); // Fecha de inicio del periodo
          const endDate = new Date(period.endDate); // Fecha de finalización del periodo
          return currentDate >= startDate && currentDate <= endDate; // Periodos activos
        });

        setPeriods(filteredPeriods);
      } catch (error) {
        console.error("Error al obtener los períodos:", error);
      }
    };

    fetchPeriods();
  }, []);

  const handleInscribirse = (raceName, distance, periodoInscripcion, price, image) => {
    const numericPrice = parseFloat(price);
    const priceTax = 0;
    // Redirige al componente Forms con los datos seleccionados
    navigate(`/${raceName}/forms1`, {
      state: { distance, periodoInscripcion, price: numericPrice, priceTax: priceTax.toFixed(2), image },
    });
  };

  return (
    <Box
      sx={{
        backgroundColor: "#f5f5f5",
        padding: 4,
        borderRadius: 2,
        maxWidth: "90%",
        margin: "auto",
        marginTop: 4,
        marginBottom: 4,
        fontFamily: "Arial, sans-serif",
        boxShadow: "0px 1px 20px rgba(100, 0, 0, 100)",
      }}
    >
      {/* Banner Image */}
      <Box
        component="img"
        src={img1}
        alt="Banner"
        sx={{
          width: "100%",
          borderRadius: 2,
          marginBottom: 3,
          maxHeight: "300px",
          boxShadow: "0px 1px 10px black",
        }}
      />
      
      <List sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
          <ListItemIcon sx={{ minWidth: 'auto', marginRight: '8px' }}>
            <CalendarMonthIcon style={{ color: 'red', fontSize: '30px' }} />
        </ListItemIcon>
        <ListItemText primary="15 de Marzo del 2025" primaryTypographyProps={{ style: { fontSize: '1.2rem', textAlign: 'left' } }} />
      </List>

       <Typography
               variant="h6"
               gutterBottom
               sx={{
                 marginTop: 1,
                 fontFamily: "Monserat, sans-serif",
                 fontWeight: 600,
                 fontSize: "1.5rem",
                 textTransform: "uppercase",
                 textDecoration: "underline",
                 textDecorationColor: "red",
                 textDecorationThickness: "2px",
               }}
             >
               Inscripciones
             </Typography>

            <Grid item xs={12} sm={12}>
                <Grid container spacing={4} justifyContent="center">
                  {periods.length > 0 ? (
                    periods.map((period) => (
                      <Grid item xs={12} sm={4} md={3} key={period.id}>
                        <Card
                          variant="outlined"
                          sx={{
                            maxWidth: 300,
                            transition: "0.2s",
                            boxShadow: "0px 1px 20px rgba(100, 0, 0, 100)",
                            borderRadius: 3,
                            "&:hover": {
                              transform: "scale(1.05)",
                            },
                          }}
                        >
                          <CardContent>
                            <Typography variant="h2" gutterBottom>
                              {period.distance}
                            </Typography>
                            <Typography variant="h7" gutterBottom>
                              {period.periodName}
                            </Typography>
                            <Typography variant="h5">${period.price}</Typography>
                          </CardContent>
                          <Box sx={{ textAlign: "center", paddingBottom: 2 }}>
                            <Button
                              variant="contained"
                              color="error"
                              onClick={() =>
                                handleInscribirse(
                                  "paypalinteg",
                                  period.distance,
                                  period.periodName,
                                  period.price,
                                  img1
                                )
                              }
                              disabled={isSoldOut} // Desactiva el botón si los cupos están agotados
                            >
                              Inscribirse
                            </Button>
                            {isSoldOut && (
                              <Box
                                sx={{
                                  textAlign: "center",
                                  backgroundColor: "rgba(255, 0, 0, 0.7)",
                                  color: "white",
                                  padding: 1,
                                }}
                              >
                                <Typography variant="h6">Cupos agotados</Typography>
                                <Typography
                                  variant="h6"
                                  sx={{ fontSize: "0.8rem" }}
                                >
                                  Proximamente nuevo periodo
                                </Typography>
                              </Box>
                            )}
                          </Box>
                        </Card>
                      </Grid>
                    ))
                  ) : (
                    <Typography variant="h6" gutterBottom sx={{marginTop: 8, fontFamily: "Monserat, sans-serif", fontWeight: 600, fontSize: "1.5rem"}}>Próximamente disponibles!</Typography>
                  )}
                </Grid>
              </Grid>
          </Box>
        );
      };

export default PaypalInteg;
